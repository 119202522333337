<script>
export default {
    props: {
        title: { type: String, required: true },
        price: { type: String, required: true },
        details: { type: Array, required: true },/** string[] */
        link: { type: String, required: false }
    }
}
</script>
<template>
    <div class="solution-card h-full d-flex flex-column app-shadow app-radius mb-4 mb-sm-8 mb-md-0">
        <h3 class="black--text text-lg-2 text-center font-weight-bold mb-4 mb-sm-9">{{ title }}</h3>
        <h1 class="text-center font-weight-bold mb-4 price mb-sm-9">R${{ price }}</h1>
        <ul>
            <li v-for="d in details" :key="d" class="d-flex align-center mb-4">
                <img src="@/assets/check.svg" class="mr-3" />
                <p class="text-base-1 black--text ma-0">
                    {{ d }}
                </p>
            </li>
        </ul>
        <div class="flex-grow-1"></div>
        <div>
            <a v-if="link" :href="link" class="primary--text font-weight-semibold">Ler mais</a>
        </div>
    </div>
</template>
<style scoped>
.solution-card {
    border-top: 0.375rem solid #47C178;
    padding: 2.25rem;
    cursor: pointer;
}

.price {
    font-size: 36px;
    color: #47C178;
}

@media screen and (min-width: 960px) {
    .solution-card {
        min-height: 28.5rem;
    }
}

.solution-card>ul {
    padding: 0px;
    list-style: none;
}
</style>