
<script>
export default {
    props: {
        icon: { type: String, required: true },
        title: { type: String, required: true },
        description: { type: String, required: true }
    }
}
</script>
<template>
    <div class="benifit-card">
        <v-icon class="b-icon" color="primary">{{ icon }}</v-icon>
        <h3 class="text-lg-2 black--text font-weight-bold mb-2">
            {{ title }}
        </h3>
        <p class="text-base-3 gray--text ma-0">
            {{ description }}
        </p>
    </div>
</template>
<style scoped>
.benifit-card {
    background: white;
    width: 100%;
    margin-bottom: 1rem;
    padding: 2rem;
    border-radius: 6px;
}

.b-icon {
    font-size: 50px;
}

@media screen and (min-width: 1264px) {
    .benifit-card {
        padding: 1.5rem;
        margin-bottom: 2rem;
    }
}

.benifit-card>img {
    height: 2.5rem;
    margin-bottom: 1rem;
}
</style>