<template>
  <div class="menu-page" style="padding: 0px !important">
    <AppHeader />
    <section id="hero" ref="inicio">
      <v-row class="pa-12 pb-0 sm-pt">
        <v-col cols="12" lg="5" md="5" sm="12">
          <div class="">
            <h2 class="text-3xl black--text mb-4 font-weight-black">Procurando o <span class="primary--text">histórico</span> de um veículo pela <span class="primary--text">placa?</span><br /></h2>
            <p class="gray--text text-lg mb-0">Descubra o histórico do veículo de forma rápida e simples! Realize uma consulta pela placa e obtenha uma variedade de dados.</p>
          </div>
          <div class="mt-4">
            <v-text-field class="mb-4" @keyup="uppercase" v-model="plate" type="text" v-mask="'AAA-#N##'" label="Placa" placeholder="Digite a placa do veículo" hide-details="auto" outlined></v-text-field>
            <v-text-field class="mb-2" v-model="whatsapp" v-mask="'(##) #####-####'" label="Whatsapp" placeholder="Digite seu whatsapp" hide-details="auto" outlined></v-text-field>

            <v-btn block class="button1" @click="checkPlate" :loading="isLoading">Consultar placa</v-btn>
          </div>
        </v-col>

        <v-col cols="12" lg="7" md="7" sm="12">
          <img class="car-animation" src="@/assets/hb20.png" height="650" />
        </v-col>
      </v-row>
    </section>

    <section id="benifits" ref="informacoes" class="pa-12 primary curved-color-1 section-app">
      <div class="section-app-content pt-6">
        <v-row>
          <v-col cols="12">
            <h2 class="text-3xl black--text font-weight-black mb-7 big-margin">Descubra todos os detalhes sobre o veículo</h2>
          </v-col>

          <v-col cols="12">
            <div class="d-flex flex-column justify-center">
              <p class="white--text text-lg text-justify mb-0">Obtenha informações cruciais sobre qualquer veículo, incluindo registros de quilometragem, informações de registro completas, histórico de batidas, restrições, pendências, multas e muito mais.</p>
            </div>
          </v-col>

          <v-col cols="12">
            <div id="benifits-grid">
              <ul class="pa-0">
                <li>
                  <BenifitCard icon="mdi-file-document-multiple-outline" title="Dados de registro" description="Os dados de registro de um veículo são informações detalhadas que incluem informações importantes sobre a identificação, histórico e propriedade do veículo." />
                </li>
                <li>
                  <BenifitCard icon="mdi-counter" title="Histórico de quilometragem"
                    description="Quantidade de quilômetros que o veículo percorreu ao longo do tempo. Essa informação é importante para avaliar o desgaste do veículo, sua manutenção e verificar se as informações fornecidas pelo vendedor ou proprietário são precisas." />
                </li>
                <li>
                  <BenifitCard icon="mdi-car-wrench" title="Batidas e sinistros" description="Dados sobre incidentes de colisão com indenização total ou parcial pela seguradora." />
                </li>
              </ul>
              <ul class="pa-0">
                <li>
                  <BenifitCard icon="mdi-car-off" title="Restrições e impedimentos"
                    description="Restrições e impedimentos são notações que indicam se o veículo está sob financiamento, envolvido em litígios judiciais, sujeito a bloqueios devido a penhoras, ou outras situações que podem afetar ou complicar a transferência de propriedade para você." />
                </li>
                <li>
                  <BenifitCard icon="mdi-robber" title="Roubo e furto" description="Informações sobre casos de roubo ou furto associados ao veículo pesquisado, incluindo detalhes do boletim de ocorrência, como a data do incidente e o status atual da restrição de roubo ou furto." />
                </li>
                <li>
                  <BenifitCard icon="mdi-currency-usd" title="Débitos e multas" description="Detalhes abrangentes sobre obrigações financeiras pendentes, como IPVA, licenciamento, seguro DPVAT e multas, que estão registrados no departamento de trânsito (Detran) onde o veículo está registrado." />
                </li>
                <li>
                  <BenifitCard icon="mdi-gavel" title="Leilão" description="Saiba se o seu veículo já teve alguma passagem por leilão." />
                </li>
              </ul>
            </div>
          </v-col>
        </v-row>
      </div>
    </section>

    <section id="solutions" ref="solucoes" class="pa-12 white section-app">
      <div class="section-app-content d-flex flex-column justify-center align-center">
        <h2 class="text-2xl mb-8 mb-sm-15 font-weight-black black--text">Nossas Soluções</h2>
        <div class="section-grid">
          <SolutionCard @click.native="scrollToTop" :title="product_1.name" :price="product_1.price" :details="product_1.items" />
          <SolutionCard @click.native="scrollToTop" :title="product_2.name" :price="product_2.price" :details="product_2.items" />
          <!--<SolutionCard @click.native="scrollToTop" :title="product_3.name" :price="product_3.price" :details="product_3.items" />-->
        </div>
      </div>
    </section>

    <section id="" class="pa-12 primary curved-color-2">
      <v-row class="pt-8">
        <v-col cols="12" lg="6" md="6" sm="12">
          <v-img src="@/assets/carros.png" />
        </v-col>

        <v-col cols="12" lg="6" md="6" sm="12" class="d-flex flex-column justify-center">
          <h3 class="text-2xl black--text font-weight-black" style="max-width: 27rem">Consultas confiáveis</h3>
          <p class="mt-4 text-lg white--text mb-sm-10 mb-4">
            Nossa tecnologia avançada nos permite acessar informações cruciais, como o registro de quilometragem, dados cadastrais, histórico de acidentes, restrições legais, pendências financeiras e muito mais. Ao obter essas informações de maneira rápida e conveniente, nossos clientes podem tomar
            decisões importantes ao negociar veículos.
          </p>
        </v-col>
      </v-row>
    </section>

    <section ref="faq" class="pa-12">
      <div class="faq">
        <h2 class="font-weight-black text-2xl text-center mb-10 black--text">Perguntas frequentes</h2>
        <v-expansion-panels style="max-width: 800px">
          <v-expansion-panel v-for="(item, i) in faq_items" :key="i">
            <v-expansion-panel-header>
              {{ item.title }}
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              {{ item.content }}
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </div>
    </section>

    <section id="about-us-banner" ref="missao" class="primary curved-color-3 section-app">
      <div class="section-app-content d-flex flex-column align-center justify-center">
        <h2 class="black--text text-2xl font-weight-black mb-7 text-center">Nossa Missão</h2>
        <p class="text-lg white--text mb-sm-10 mb-4 text-center">
          Na Busca Veicular, buscamos oferecer confiança e segurança aos compradores de veículos usados e seminovos. Nossa missão é fornecer análises completas e específicas por meio de nosso site de consultas veiculares, capacitando os compradores a evitar prejuízos e fraudes. Estamos comprometidos
          em tornar a compra de veículos usados uma experiência tranquila e confiável, promovendo transparência e informação em cada negociação.
        </p>
      </div>
    </section>
    <AppFooter />

    <v-dialog v-model="dialog" fullscreen hide-overlay transition="dialog-bottom-transition" scrollable>
      <div :class="`${step != 3 ? 'success' : 'white from-center'} steps`">
        <div class="step1 mt-12 pa-6" v-if="step === 1 || step === 2">
          <img class="logo" src="@/assets/logo_preto.png" />
          <div class="info-1 fade" v-if="step === 1">Por favor aguarde, estamos efetuando a consulta...</div>
          <div class="info-1 fade" v-if="step === 1">Dentro de poucos segundos, você terá acesso a informações cruciais sobre o veículo, tornando suas decisões mais simples.</div>
          <mike-pad v-if="step === 1" class="spinner mt-12"></mike-pad>

          <v-alert class="fade mt-6" color="amber darken-2" type="warning" v-if="step === 2">Encontramos informações importantes sobre esse veículo</v-alert>
        </div>

        <div class="step3 fade" v-if="step === 3">
          <v-row>
            <v-col cols="12" lg="6" md="6" sm="12">
              <v-card class="box box-car" elevation="2">
                <span class="result-title" color="success">Resultado da consulta</span>
                <div class="mt-4 d-flex flex-row justify-space-around align-center">
                  <img v-if="car_data.logo" :src="car_data.logo" class="brand-logo" />

                  <div class="plate-logo">
                    <div class="d-flex flex-column">
                      <span>Placa:</span>
                      <span class="mt-2"><b>{{ car_data.placa }}</b></span>
                    </div>
                  </div>
                </div>

                <div class="cards-container">
                  <InfoCard :info1="{ title: 'Origem do veículo', data: car_data.municipio }" class="mt-4"></InfoCard>
                  <InfoCard :info1="{ title: 'Idade do veículo', data: getIdade(car_data) }" class="mt-4"></InfoCard>
                </div>

                <v-alert class="fade mt-8 black--text" color="amber lighten-4" type="warning">
                  <template v-slot:prepend>
                    <v-icon large color="black"> mdi-car-side </v-icon>
                  </template>

                  <span class="red--text"><b>Atenção:</b></span> Encontramos registros importantes do seu veículo. Confira os detalhes na consulta completa.
                </v-alert>
              </v-card>



              <v-card class="mt-4" elevation="2" style="background-color: #f5f5f9 !important;">
                <v-toolbar class="card-toolbar" elevation="0">
                  <v-toolbar-title style="font-size: 24px;">Dados básicos do veículo</v-toolbar-title>
                </v-toolbar>

                <div class="info-card-container">
                  <InfoCard :info1="{ title: 'Placa', data: car_data.placa }" :info2="{ title: 'Renavam', data: car_data.extra.renavam }" class="mt-4"></InfoCard>
                  <InfoCard :info1="{ title: 'Fabricação', data: car_data.ano }" :info2="{ title: 'Modelo e Marca ', data: car_data.MARCA + ' ' + car_data.MODELO }" class="mt-4"></InfoCard>
                  <InfoCard :info1="{ title: 'Cor', data: car_data.cor }" :info2="{ title: 'Procedência', data: car_data.origem }" class="mt-4"></InfoCard>
                  <InfoCard :info1="{ title: 'Numero do motor', data: car_data.motor }" :info2="{ title: 'Chassi', data: car_data.chassi || car_data.extra.chassi }" class="mt-4"></InfoCard>
                  <InfoCard :info1="{ title: 'Ano Modelo', data: car_data.anoModelo }" :info2="{ title: 'Situação', data: car_data.situacao }" class="mt-4"></InfoCard>
                  <InfoCard :info1="{ title: 'Combustível', data: car_data.extra.combustivel }" :info2="{ title: 'Município', data: car_data.municipio }" class="mt-4"></InfoCard>
                </div>
              </v-card>
            </v-col>

            <v-col cols="12" lg="6" md="6" sm="12">
              <v-card elevation="2" style="background-color: #f5f5f9 !important; height: 100%;">
                <v-toolbar class="card-toolbar" elevation="0">
                  <v-toolbar-title style="font-size: 24px;">Não tenha surpresas!</v-toolbar-title>
                </v-toolbar>



                <div class="info-card-container">

                  <v-alert class="fade black--text" color="amber lighten-4" type="warning">
                    <template v-slot:prepend>
                      <v-icon large color="red"> mdi-alert-circle-outline </v-icon>
                    </template>

                    O veiculo consultado pode ter pendências como:
                  </v-alert>

                  <v-row>
                    <v-col cols="4">
                      <AlertCard text="Quantidade de proprietários"></AlertCard>
                    </v-col>
                    <v-col cols="4">
                      <AlertCard text="Chassi remarcado"></AlertCard>
                    </v-col>
                    <v-col cols="4">
                      <AlertCard text="Restrição judicial"></AlertCard>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col cols="4">
                      <AlertCard text="Passagem por leilão" :isRed="true"></AlertCard>
                    </v-col>
                    <v-col cols="4">
                      <AlertCard text="Débitos e multas"></AlertCard>
                    </v-col>
                    <v-col cols="4">
                      <AlertCard text="Histórico de roubo e furto"></AlertCard>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col cols="4">
                      <AlertCard text="Indício de sinistro" :isRed="true"></AlertCard>
                    </v-col>
                    <v-col cols="4">
                      <AlertCard text="Restrições administrativas"></AlertCard>
                    </v-col>
                    <v-col cols="4">
                      <AlertCard text="Restrições financeiras"></AlertCard>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col cols="12">
                      <div class="consultas">
                        Consultas
                      </div>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col cols="12">
                      <v-card class="pricing-card" elevation="1">
                        <div class="card-info-text">
                          <img src="@/assets/info.svg" style="max-width: 100%;">
                        </div>

                        <span class="card-info-product">
                          Consulta completa
                        </span>

                        <img src="@/assets/price1.png" style="max-width: 100%; margin: auto;" class="mt-4 mb-6">

                        <div class="card-btn" @click="openPayment('Consulta completa', 'https://mpago.la/1EhWtot')">
                          Fazer consulta agora
                        </div>
                      </v-card>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col cols="12">
                      <v-card class="pricing-card" elevation="1">
                        <div class="card-info-text">
                          <img src="@/assets/info.svg" style="max-width: 100%;">
                        </div>

                        <span class="card-info-product">
                          Consulta débitos e multas
                        </span>

                        <img src="@/assets/price2.png" style="max-width: 100%; margin: auto;" class="mt-4 mb-6">

                        <div class="card-btn" @click="openPayment('Consulta essencial', 'https://mpago.la/1y5R1bP')">
                          Fazer consulta agora
                        </div>
                      </v-card>
                    </v-col>
                  </v-row>
                </div>
              </v-card>
            </v-col>
          </v-row>
        </div>
      </div>
    </v-dialog>
  </div>
</template>

<script>
import Api from "@/lib/Api";
import HeroButton from "@/components/HeroButton";
import InfoCard from "@/components/InfoCard";
import AlertCard from "@/components/AlertCard";
import BenifitCard from "@/components/BenifitCard";
import SolutionCard from "@/components/SolutionCard.vue";
import { emitToastr } from "@/lib/Utils";
import { MikePad } from "vue-loading-spinner";

export default {
  name: "HomeView",

  data: () => ({
    whatsapp: "",
    plate: "",
    dialog: false,
    isLoading: false,
    step: "Consultando...",
    car_data: {},
    product_1: {
      name: "Consulta completa",
      id: "1483019403-794f5a9f-9f5a-4bc8-82ff-9e2188f1871f",
      price: "29,90",
      items: [
        "Dados de leilão",
        "Batidas",
        "Restrições e impedimentos",
        "Fotos do veículo (se houver)",
        "Roubo e furto",
        "Risco de comercialização",
        "Número do Renavam",
        "Aceitação em seguro",
        "Histórico de KM (se houver)",
        "Score de leilão",
        "Dados cadastrais nacionais",
        "Gráfico de preço",
        "Recall",
        "Decodificador de chassi",
        "Dados cadastrais estaduais",
        "Precificador",
        "Gravame",
        "Ficha técnica",
        "Débitos e multas",
        "Opinião do dono",
        "Principais falhas do modelo",
        "Renajud detalhe",
        "Localizador de agregados",
        "Custo médio de manutenção do veículo",
      ],
    },
    product_2: {
      name: "Consulta essencial",
      id: "1483019403-221f9000-18ed-4e4e-9317-fbfe2505cef5",
      price: "9,90",
      items: ["Número do Renavam", "Aceitação em seguro", "Dados de leilão", "Score de leilão", "Dados cadastrais nacionais", "Batidas", "Dados cadastrais estaduais", "Débitos e multas", "Opinião do dono"],
    },
    product_3: { name: "Consulta simples", id: "1483019403-a9900f4f-4122-4f96-a249-a289b2d04920", price: "0,00", items: ["Número do Renavam", "Aceitação em seguro", "Dados de leilão", "Score de leilão", "Dados cadastrais nacionais", "Batidas", "Dados cadastrais estaduais"] },
    faq_items: [
      {
        title: "O que é o Busca Veicular?",
        content: "O Busca Veicular desempenha a função de um sistema informativo que tem como meta descobrir a procedência e a trajetória de veículos, com o intuito de auxiliar na redução de riscos ao adquirir um carro usado. Efetuamos consultas exclusivamente em fontes de dados oficiais.",
      },
      {
        title: "A consulta é grátis?",
        content: "Todas as consultas em nosso site são cobradas. Temos 3 tipos de consultas disponíveis atualmente.",
      },
      {
        title: "É possível consultar apenas pela placa?",
        content: "Sim, você consegue fazer a pesquisa completa com apenas a PLACA do veículo.",
      },
      {
        title: "Fiquei com dúvidas sobre a minha consulta, como eu faço?",
        content: "Para qualquer dúvida relacionado a sua consulta, pode entrar em contato conosco através do email atendimento@buscaveicular.com.br",
      },
      {
        title: "Qual é a diferença entre o Busca Veicular e a consulta do Detran?",
        content:
          "As consultas no Detran são oferecidas de forma gratuita e são mais simples em comparação com a maioria das pesquisas realizadas na plataforma Busca Veicular. Através delas, você pode acessar informações de registro e verificar débitos e multas, mas é necessário ter o número do Renavam ou mesmo o chassi do veículo. Enquanto isso, as consultas disponíveis na Busca Veicular proporcionam uma quantidade significativamente maior de informações e até mesmo dados exclusivos (na consulta completa). Além disso, nossas consultas são uniformes e podem ser facilmente realizadas através da placa do veículo. Vale destacar que as consultas do Detran variam de um estado para outro, devido à falta de padronização nos serviços prestados.",
      },
      {
        title: "O que é indício de sinistro e quais situações podem levar a esse tipo de registro?",
        content:
          "Sinistro é o termo usado para descrever qualquer situação que resulte em danos ou prejuízos ao proprietário de um bem assegurado. Um exemplo típico de sinistro ocorre quando você sofre um acidente de carro involuntário e possui uma apólice de seguro que cobre colisões. Outro exemplo é quando seu veículo é roubado ou furtado, e essa situação também está prevista na sua apólice de seguro.",
      },
      {
        title: "Quais riscos o leilão pode trazer para o veículo?",
        content:
          "O registro de um veículo em leilão, além de depreciá-lo, pode apresentar desafios adicionais, como a recusa da seguradora, dificuldades no financiamento, dificuldades de revenda e, em casos extremos, pode representar riscos para a sua apólice se o veículo estiver envolvido em um dano muito severo, como uma perda total (popularmente conhecida como PT, quando o custo dos reparos excede o valor do bem).",
      },
      {
        title: "O que é Risco de Comercialização de Mercado?",
        content:
          "Esse registro é feito quando um veículo passa por algum tipo de reparo ou tem alguma restrição em relação à seguradora. É importante observar que esse registro não reflete a situação final com a seguradora, uma vez que os critérios de aceitação variam internamente e são exclusivos de cada seguradora, não sendo compartilhados. Recomendamos utilizá-lo como uma informação de apoio em conjunto com outros indicadores de sinistralidade.",
      },
    ],
  }),

  components: { HeroButton, BenifitCard, SolutionCard, MikePad, InfoCard, AlertCard },

  computed: {},

  async mounted() {
    this.$root.$refs.inicioSection = this.$refs.inicio;
    this.$root.$refs.informacoesSection = this.$refs.informacoes;
    this.$root.$refs.solucoesSection = this.$refs.solucoes;
    this.$root.$refs.faqSection = this.$refs.faq;
    this.$root.$refs.missaoSection = this.$refs.missao;
  },

  methods: {
    scrollToTop() {
      document.getElementById("hero").scrollIntoView({ behavior: "smooth" });
    },

    async productClickEvent(product) {
      await Api.sendUserData(product, "", "cliques");
    },

    getIdade(car_data) {
      let ano;

      if (car_data.ano) {
        ano = car_data.ano;
      }
      else {
        if (car_data.extra && car_data.extra.ano_fabricacao) {
          ano = car_data.extra.ano_fabricacao;
        }
        else {
          if (car_data.anoModelo) {
            ano = car_data.anoModelo;
          }
        }
      }

      if (!isNaN(ano)) {
        ano = Number(ano);
        const hoje = new Date().getFullYear();

        const idade = hoje - ano;

        if (idade === 0) return "Menos de 1 ano"
        if (idade === 1) return "1 ano"
        return `${idade} anos`;
      }
      else {
        return "** anos"
      }
    },

    async checkPlate() {
      if (!this.whatsapp) return emitToastr("error", "O preenchimento do whatsapp é obrigatório");
      if (!this.plate) return emitToastr("error", "O preenchimento da placa é obrigatório");

      if (this.whatsapp.length < 14) {
        return emitToastr("error", "Whatsapp em formato incorreto. Por favor digite o número com DDD");
      }

      if (this.plate.length != 8) {
        return emitToastr("error", "Placa em formato incorreto. Por favor, verifique essa informação");
      }

      this.isLoading = true;
      this.step = 1;
      const resp = await Api.getCarData(this.plate);
      if (!resp.error && resp.message && (resp.message.MARCA || resp.message.marca)) {
        this.car_data = resp.message;
      }
      else {
        this.isLoading = false;
        emitToastr("error", "Erro ao consultar. Verifique a placa inserida e tente novamente mais tarde.");
        return;
      }

      await Api.sendUserData(this.whatsapp, this.plate, 'leads');

      this.dialog = true;

      setTimeout(() => {
        this.step = 2;

        setTimeout(() => {
          this.step = 3;

        }, 3 * 1000); //

      }, 8 * 1000); //
      return;
    },

    uppercase() {
      this.plate = this.plate.toUpperCase();
    },

    async openPayment(type, product) {
      if (this.loading) return;

      this.loading = true;
      await Api.sendUserData(type, "", "cliques");
      this.loading = false;
      window.location.replace(product);
    }
  },
};
</script>

<style scoped>
.button1 {
  background: rgb(73, 196, 30);
  background: -moz-linear-gradient(45deg, rgba(73, 196, 30, 1) 61%, rgba(11, 255, 109, 1) 100%);
  background: -webkit-linear-gradient(45deg, rgba(73, 196, 30, 1) 61%, rgba(11, 255, 109, 1) 100%);
  background: linear-gradient(45deg, rgba(73, 196, 30, 1) 61%, rgba(11, 255, 109, 1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#49c41e", endColorstr="#0bff6d", GradientType=1);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#49c41e", endColorstr="#0bff6d", GradientType=1);
  height: 56px !important;
  color: white;
  font-weight: bold;
  font-size: 16px;
}

#hero {
  padding-top: 90px;
}

.curved-color-1 {
  background-image: url("@/assets/wave1.svg");
  background-size: 100%;
  background-position-y: -5px;
}

.curved-color-2 {
  background-image: url("@/assets/wave2.svg");
  background-size: 100%;
  background-position-y: -5px;
}

.curved-color-3 {
  background-image: url("@/assets/wave3.svg");
  background-size: 100%;
  background-position-y: -5px;
}

.product-card {
  overflow: hidden;
}

.products-row {
  width: 100%;
}

.card-title-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100%;
}

.card-title {
  font-size: 22px;
  font-weight: bold;
}

.card-price {
  font-size: 36px;
  color: #47c178;
  font-weight: bold;
  margin-bottom: 10px;
}

.card-item {
  text-align: start;
  margin-bottom: 3px;
  font-size: 14px;
}

.steps {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  text-align: center;
  min-height: 100vh;
  height: max-content;
  padding-top: 15px;
  overflow: hidden;
  color: white;
  display: inline-block;
  transition: 1s;
}

.step1 {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  text-align: center;
  height: max-content;
  overflow: hidden;
  color: white;
  max-width: 600px;
  min-height: 100vh;
  text-align: left;
  margin: auto;
}

.step3 {
  max-width: 1300px;
  margin: auto;
  margin-top: 30px;
}

.box {
  background-color: #f5f5f9 !important;
  padding: 20px;
  border-radius: 5px;
  color: #47c178;
}

.result-title {
  font-size: 36px;
  line-height: 36px;
  font-weight: bold;
}

.brand-logo {
  height: 200px;
}

.plate-logo {
  color: #4f4f4f;
  font-size: 38px;
  line-height: 38px;
}

.fade {
  animation: fadeIn 1s;
}

.spinner {
  margin-top: 30px;
  filter: brightness(100);
  transform: scale(1.6);
}

.logo {
  width: 80vw;
  max-width: 500px;
}

.info-1 {
  font-size: 18px;
  width: 100%;
  margin-top: 30px;
}

.card-toolbar {
  color: white;
  padding: 0px;
  width: 100%;
  background: rgb(96, 231, 72);
  background: -moz-linear-gradient(45deg, rgba(46, 183, 77, 1) 50%, rgba(96, 231, 72, 1) 100%);
  background: -webkit-linear-gradient(45deg, rgba(46, 183, 77, 1) 50%, rgba(96, 231, 72, 1) 100%);
  background: linear-gradient(45deg, rgba(46, 183, 77, 1) 50%, rgba(96, 231, 72, 1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#60e748", endColorstr="#2eb74d", GradientType=1);
}

.info-card-container {
  padding: 20px;
}

.consultas {
  color: #343434;
  font-size: 30px;
  font-weight: bold;
  border-radius: 6px;
  border: 2px solid #2EB74D;
  padding: 12px;
}

.pricing-card {
  background-color: #e6ecf3;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.card-info-product {
  color: #343434;
  font-size: 24px;
  font-weight: bold;
}

.card-btn {
  cursor: pointer;
  width: 100%;
  height: 50px;
  position: absolute;
  bottom: 0;
  left: 0;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 22px;
  border-top-right-radius: 0px !important;
  border-top-left-radius: 0px !important;
  border-bottom-right-radius: 6px;
  border-bottom-left-radius: 6px;
  background: rgb(96, 231, 72);
  background: -moz-linear-gradient(45deg, rgba(46, 183, 77, 1) 50%, rgba(96, 231, 72, 1) 100%);
  background: -webkit-linear-gradient(45deg, rgba(46, 183, 77, 1) 50%, rgba(96, 231, 72, 1) 100%);
  background: linear-gradient(45deg, rgba(46, 183, 77, 1) 50%, rgba(96, 231, 72, 1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#60e748", endColorstr="#2eb74d", GradientType=1);
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@media screen and (min-width: 600px) {
  #benifits-grid {
    display: grid;
    grid-template-columns: 6fr 6fr;
    column-gap: 1rem;
    align-items: stretch;
    justify-items: center;
  }
}

@media screen and (min-width: 1264px) {
  #benifits-grid {
    column-gap: 2rem;
  }
}

#benifits-grid>ul {
  display: flex;
  justify-content: center;
  flex-direction: column;
  list-style: none;
}

.section-grid {
  display: flex;
  flex-direction: column;
  align-items: stretch;
}

.section-grid> :first-child {
  margin-bottom: 4rem;
}

.faq {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

@media screen and (min-width: 960px) {
  .section-grid {
    display: grid;
    grid-template-columns: 6fr 6fr;
    column-gap: 3rem;
  }

  .section-grid> :first-child {
    margin-bottom: unset;
  }
}

@media screen and (min-width: 1264px) {
  .section-grid {
    grid-template-columns: 33.75rem 33.75rem;
    column-gap: 5.75rem;
  }

  .big-margin {
    margin-top: 60px;
  }
}

#solutions {
  padding-bottom: 6rem;
}

@media screen and (min-width: 960px) {
  #solutions>.section-app-content>.section-grid {
    /*grid-template-columns: 4fr 4fr 4fr;*/
    column-gap: 1.25rem;
    row-gap: 2rem;
    margin-bottom: 0px;
  }
}

@media screen and (max-width: 960px) {

  .car-animation {
    display: none !important;
  }

  .sm-pt {
    padding-top: 0px !important;
  }
}

@media screen and (min-width: 1264px) {
  #solutions>.section-app-content>.section-grid {
    column-gap: 1.875rem;
    row-gap: 2.875rem;
  }
}

#about-us {
  padding-bottom: 3.125rem;
}

#about-us-details {
  padding-bottom: 6rem;
  padding-top: 6rem;
}

#about-us-banner {
  padding-bottom: 6rem;
  padding-top: 6rem;
}

@media screen and (max-width: 600px) {
  .brand-logo {
    height: 100px;
  }

  .plate-logo {
    font-size: 28px;
    line-height: 28px;
  }

  .info-card-container {
    padding: 15px;
  }

  .box {
    padding: 15px;
  }

  .pricing-card {
    padding: 15px;

  }

  .step3 {
    margin-left: 20px;
    margin-right: 20px;
    margin-top: 15px;
  }

}


#about-image {
  aspect-ratio: 5/3;
}

@media screen and (min-width: 600px) {
  #about-image {
    aspect-ratio: 1920/592;
  }
}

.car-animation {
  display: block;
  animation: 1.1s ease-out 0s 1 slideInFromLeft;
}

@keyframes slideInFromLeft {
  0% {
    transform: translateX(20%);
  }

  100% {
    transform: translateX(0);
  }
}
</style>
