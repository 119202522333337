<script>

export default {
    data: () => ({
        showMenu: false
    }),
    methods: {

    },
    computed: {
        pages() {
            const pages = [
                { ref: 'inicioSection', title: "Início" },
                { ref: 'informacoesSection', title: "Informações do veículo" },
                { ref: 'solucoesSection', title: "Soluções" },
                { ref: 'faqSection', title: "FAQ" },
            ]

            for (let page of pages) {
                if (page.path == this.$route.path) {
                    page.selected = true
                }
            }

            return pages;
        }
    },
}

</script>
<template>
    <header class="section-app white app-shadow" id="header">
        <div class="bar d-flex justify-center align-center">
            <h1>
                <span style="color: transparent" class="absolute">Busca Veicular</span>
                <v-img id="logo" class="logo" src="@/assets/logo.png" alt="Logotipo" contain />
            </h1>
            <div class="flex-grow-1">
            </div>
            <nav class="hidden-sm-and-down">
                <ul>
                    <li :key="page.path" v-for="page in pages" class="font-weight-bold pointer" @click="$root.$refs[page.ref].scrollIntoView({ block: 'start', behavior: 'smooth' });">
                        {{ page.title }}
                    </li>
                </ul>
            </nav>
            <div class="flex-grow-1">
            </div>

        </div>
    </header>
</template>
<style scoped>
#header {
    z-index: 50;
    height: 75px;
    position: fixed;
}

.logo {
    width: 15rem;
    max-height: 50px;
}

#burger,
#close {
    width: 2rem;
    height: 2rem;
}

nav {
    width: 30rem;
}

.bar {
    width: 100%;
    margin-left: 15px;
    margin-right: 15px;
}

nav>ul {
    display: flex;
    align-items: center;
    list-style: none;
    justify-content: space-between;
    padding: 0px;
}

nav>ul>li>a {
    color: #9497A1;
    font-weight: 500;
    letter-spacing: -0.01em;
}

nav>ul>li>a.selected {
    color: #292D33;
    font-weight: bold;
}
</style>