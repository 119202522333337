
<template>
    <v-card class="alert-container">
        <v-icon large :color="`${!isRed ? 'amber' : 'red'}`">mdi-alert-outline</v-icon>
        <span class="mt-1">{{ text }}</span>
    </v-card>
</template>

<script>
export default {
    props: ["text", "isRed"],
    data: () => ({

    }),
    methods: {

    },
}

</script>


<style scoped>
.alert-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100px;
    padding: 20px;
    background-color: transparent;
}

@media screen and (max-width: 600px) {
    .alert-container {
        padding: 5px;
    }
}
</style>