import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        themes: {
            light: {
                white: "#FFFFFF",
                primary: '#47C178',
                secondary: '#467DFF',
                'secondary-dark': '#002B4E',
                black: '#292D33',
                'light-blue': '#F1F6FA',
                "bright-blue": '#F0F9FF',
                gray: '#9497A1',
            },
        },
    },
});
