<script>
export default {
    props: {
        disabled: { type: Boolean, required: false },
        color: { type: String, required: false, enum: ['primary', 'white'], default: 'primary' },
        variant: { type: String, required: false, default: 'filled', enum: ['filled' | 'outlined'] }
    }
}

</script>
<template>
    <button :class="{
        ['variant--' + variant]: true,
        ['color--' + color]: true
    }" class=" text-base font-weight-semibold app-radius" :disabled="disabled" @click="$emit('click')">
        <slot />
    </button>
</template>
<style scoped>
button {
    padding-bottom: 0.625rem;
    padding-top: 0.625rem;
    min-width: 8.5rem;
    padding-right: 0.875rem;
    padding-left: 0.875rem;
}


button.variant--filled.color--primary {
    background: #47C178;
    color: white;
}


button.variant--filled.color--white {
    background: white;
    color: #47C178;
}

button.variant--filled:disabled,
button.variant--filled[disabled] {
    background: #9497A1 !important;
}

button.variant--filled.color--primary:hover,
button.variant--filled.color--primary:focus {
    box-shadow: 0px 0.75rem 1.875rem rgba(24, 92, 255, 0.18);
    background: #467DFF !important;
}


button.variant--filled.color--white:hover,
button.variant--filled.color--white:focus {
    box-shadow: 0px 0.75rem 1.875rem rgba(24, 92, 255, 0.18);
    background: #F0F9FF !important;
}

button.variant--filled.color--primary:active {
    box-shadow: none;
    background: #47C178 !important;
}

button.variant--filled.color--white:active {
    box-shadow: none;
    background: white !important;
}

button.variant--outlined {
    border: 1px solid #47C178;
    color: #47C178;
}

button.variant--outlined:disabled,
button.variant--outlined[disabled] {
    border-color: #9497A1 !important;
    color: #9497A1 !important;
}

button.variant--outlined:hover,
button.variant--outlined:focus {
    box-shadow: 0px 0.75rem 1.875rem rgba(24, 92, 255, 0.18);
    border-color: #467DFF;
    color: #467DFF;
}

button.variant--outlined:active {
    box-shadow: none;
    border-color: #47C178;
    color: #47C178;
}
</style>