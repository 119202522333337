
<template>
    <v-card class="card-container">
        <div class="info1">
            <div class="info-container">
                <b>{{ info1.title }}</b>
                <div>
                    {{ info1.data || "********" }}
                </div>
            </div>
        </div>

        <div class="card-divider"  v-if="info2"></div>

        <div class="info2" v-if="info2">
            <div class="info-container">
                <b>{{ info2.title }}</b>
                <div>
                    {{ info2.data || "********" }}
                </div>
            </div>
        </div>

        <div class="card-border"></div>
    </v-card>
</template>

<script>
export default {
    props: ["info1", "info2"],
    data: () => ({

    }),
    methods: {

    },
}

</script>


<style scoped>
.card-container {
    background-color: #e6ecf3;
    color: #4f4f4f;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    border-radius: 6px;
    padding: 10px 30px 10px 20px;
    min-width: 200px;
    font-size: 14px;
}

.card-divider {
    width: 1px;
    height: 50px;
    position: relative;
    background: #bbbbbb;
    opacity: 0.5;
    margin-left: 10px;
    margin-right: 10px;
}

.card-border {
    width: 10px;
    height: 100%;
    position: absolute;
    right: 0;
    border-top-right-radius: 6px;
    border-top-left-radius: 0px !important;
    border-bottom-right-radius: 6px;
    border-bottom-left-radius: 0px !important;
    background: rgb(96, 231, 72);
    background: -moz-linear-gradient(45deg, rgba(96, 231, 72, 1) 50%, rgba(46, 183, 77, 1) 100%);
    background: -webkit-linear-gradient(45deg, rgba(96, 231, 72, 1) 50%, rgba(46, 183, 77, 1) 100%);
    background: linear-gradient(45deg, rgba(96, 231, 72, 1) 50%, rgba(46, 183, 77, 1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#60e748", endColorstr="#2eb74d", GradientType=1);
}

.info1 {
    flex: 1;
}

.info2 {
    flex: 1;
}


</style>