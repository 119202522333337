import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import vuetify from "./plugins/vuetify";
import "@/assets/global.css";
import "@/assets/typographics.css";
import VueMask from "v-mask";

Vue.config.productionTip = false;
Vue.use(VueMask);


import AppHeader from "@/components/app/AppHeader.vue";
import AppButton from "@/components/app/AppButton.vue";
import AppFooter from "@/components/app/AppFooter.vue";

Vue.component("AppHeader", AppHeader);
Vue.component("AppButton", AppButton);
Vue.component("AppFooter", AppFooter);

new Vue({
  router,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
