<script>
export default {
    props: { disabled: { type: Boolean, required: false } }
}

</script>
<template>
    <button class="rounded-circle pa-2" @click="$emit('click')" :disabled="disabled">
        <div class="w-full h-full rounded-circle white--text d-flex text-base align-center justify-center">
            <slot />
        </div>
    </button>
</template>
<style scoped> button {
     width: 7.5rem;
     height: 7.5rem;
     top: 0px;
     right: 0px;
     transform: translateY(-50%);
     background: #47C178;
 }

 button:disabled,
 button[disabled] {
     background: #9497A1 !important;
 }


 button:hover {
     background: #467DFF;
 }

 button:active {
     background: #47C178;
 }

 button>div {
     border: 1px solid white;
 }
</style>