import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import PaymentOK from "../views/PaymentOK.vue";
import PaymentNOK from "../views/PaymentNOK.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
    meta: {
      layout: "fullscreen",
    },
  },
  {
    path: "/pagamento-ok",
    name: "PaymentOK",
    component: PaymentOK,
    meta: {
      layout: "fullscreen",
    },
  },
  {
    path: "/pagamento-processando",
    name: "PaymentNOK",
    component: PaymentNOK,
    meta: {
      layout: "fullscreen",
    },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
