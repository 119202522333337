import axios from "axios";
import { emitToastr } from "./Utils";

const API_URL = "https://acesso.clubedocarroapp.com.br";

class Api {
  sendUserData = async (info1, info2, type = "leads") => {
    const resp = await this.authPost("/admin/temp-insert-lead-data", { info1, info2, type }, {});
    return resp;
  };

  getCarData = async (plate) => {
    const resp = await this.authPost("/admin/get-car-info", { plate }, {});
    return resp;
  };

  checkErrorsWithStatusCode200 = (data) => {
    if (data.error) return data;
    return data;
  };

  get = (url) =>
    axios
      .get(`${API_URL}${url}`)
      .then((response) => this.checkErrorsWithStatusCode200(response.data))
      .catch(() => {
        emitToastr("error", "Erro genérico de servidor!", "Erro");
      });

  authGet = (url, data) =>
    axios
      .get(`${API_URL}${url}`, { headers: {}, params: data })
      .then((response) => this.checkErrorsWithStatusCode200(response.data))
      .catch((err) => {
        if (err && err.response && err.response.status === 401) {
          if (err.response.data && err.response.data.logoff === true) {
            if (err.response.data.inactivity) {
              emitToastr("warning", err.response.data.message, "Sessão expirada");
              setTimeout(() => {
                this.logout();
              }, 5000);
            } else {
              this.logout();
            }
          } else {
            emitToastr("error", "Erro de servidor! Confira se as informações enviadas estão completas e no formato correto e tente novamente.", "Erro");
          }
        } else {
          if (err.response && err.response.data && err.response.data.error && err.response.data.message) return emitToastr("error", err.response.data.message);
          console.log("Erro genérico de servidor");
        }
      });

  authPost = (url, data, options) => {
    const headers = { auth: "" };
    if (options) {
      if (options.multipart) {
        headers["Content-Type"] = "multipart/form-data";
      }
      if (options.formData) {
        let formData = new FormData();
        for (let key in data) {
          formData.append(key, data[key]);
        }
        data = formData;
      }
    }
    return axios
      .post(`${API_URL}${url}`, data, { headers })
      .then((response) => this.checkErrorsWithStatusCode200(response.data))
      .catch((err) => {
        if (err && err.response && err.response.status === 401) {
          if (err.response.data && err.response.data.logoff === true) {
            if (err.response.data.inactivity) {
              emitToastr("warning", err.response.data.message, "Sessão expirada");
              setTimeout(() => {
                this.logout();
              }, 5000);
            } else {
              this.logout();
            }
          } else {
            emitToastr("error", "Erro de servidor! Confira se as informações enviadas estão completas e no formato correto e tente novamente.", "Erro");
          }
        } else {
          if (err.response && err.response.data && err.response.data.error && err.response.data.message) {
            return err.response.data;
          } else if (err.response && err.response.status) emitToastr("error", err.response.statusText + " (status: " + err.response.status + ")", "Erro");
          else emitToastr("error", "Erro genérico de servidor!", "Erro");
          err.response.error = err.response.error || true;
          return err.response;
        }
      });
  };
}

export default new Api();
