<template>
    <div class="view primary">
        <v-icon class="icon mb-2">mdi-check-circle-outline</v-icon>
        <div class="t1 mb-4">Pagamento Aprovado</div>
        <div class="t2">Tudo certo até aqui, obrigado! Em alguns instantes entraremos em contato por e-mail.</div>
    </div>
</template>

<script>
export default {
    name: "PaymentOKView",

    data: () => ({

    }),

    computed: {

    },

    async mounted() {

    },

    methods: {

    },
};
</script>
  

<style scoped>
.view {
    width: 100%;
    padding: 10%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: white;
    padding-bottom: 20%;
    text-align: center;
}

.icon {
    color: #58ff58;
    font-size: 60px;
}

.t1 {
    font-size: 26px;
    font-weight: 600;
}

.t2 {
    font-size: 18px;
    font-weight: 400;
}
</style>
  